import $ from 'jquery'
global.jQuery = $;
import Toggler from './js/Toggler'
import slick from 'slick-carousel'
import Dropzone from 'dropzone'


var nav = new Toggler({'#nav':'open', '#mobileBurger': 'open'});

$('#mobileBurger').on('mousedown', nav.toggle.bind(nav));
//new Navigation('#mobileBurger', {nav: 'open'});

let $banner = $('#banner');
$banner.children('.wrapper').slick({
	infinite: true,
	autoplay: true,
	autoplaySpeed: 2000,
	cssEase: 'linear',
	prevArrow: null,
	nextArrow: null
});
$banner.css({overflow: '', height: ''});


var $dropzoneWidgets = $('form .widget-dropzone input[type="file"]');

$dropzoneWidgets.each(function () {
	var $widget = $(this);
	var $hideInput = $('#' + $widget.attr('id') + '_dropzone_files');
	var $form = $($widget.parents('form'));
	var $formSubmits = $form.find('input[type="submit"]');
	var FORM_SUBMIT = $form.find('input[name="FORM_SUBMIT"]').attr('value');
	var REQUEST_TOKEN = $form.find('input[name="REQUEST_TOKEN"]').attr('value');

	var $dropzone = $widget.parent().dropzone({
		params: {
			//'FORM_SUBMIT': FORM_SUBMIT,
			'REQUEST_TOKEN': REQUEST_TOKEN,
			'UPLOAD-FILE': true
		},
		addRemoveLinks: true, // @todo removedfile bug
		clickable: true,
		dictCancelUpload: 'Abbrechen',
		dictRemoveFile: 'Abbrechen',
		//previewTemplate: '',
		url: $form.attr('action'),
		paramName: $widget.attr('name'),
		createImageThumbnails: false,
		previewTemplate: '<div class="dz-preview dz-file-preview"> <div class="dz-details"> <div class="dz-filename"><span data-dz-name></span></div> <div class="dz-size"><span data-dz-size></span></div> </div> <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div> <div class="dz-error-message"><span data-dz-errormessage></span></div> <div class="dz-success-mark"> ' +
		'</div> <div class="dz-error-mark"> ' +
		'</div></div>',
		sending: function () {
			submitUpdate();
		},
		error: function () {
			submitUpdate();
		},
		success: function () {
			submitUpdate();
			updateHideInput();
		},
		accept: function (file, done) {
			done();
		}
	}).addClass('init');

	var $text = $dropzone.children('.text').on('click', function() {
		$dropzone.trigger('click');
	});

	var drop = $dropzone.get(0).dropzone;



	drop.on('removedfile', function (file) {
		updateHideInput();
	});

	function submitUpdate() {
		var allFiles = drop.getAcceptedFiles();
		var filesCount = 0;
		var filesLength = allFiles.length;
		allFiles.forEach(function (file) {
			if(file.upload.progress === 100) {
				filesCount++;
			}
		});

		$formSubmits.prop('disabled', filesCount !== filesLength);
	}

	function updateHideInput() {
		var allFiles = drop.getAcceptedFiles();

		$hideInput.val('');
		var arrVal = [];
		allFiles.forEach(function (file) {
			if(typeof file.xhr === 'undefined') {
				return;
			}
			var jsonResponse = $.parseJSON(file.xhr.response);
			arrVal.push(jsonResponse.name);
		});

		$hideInput.val(arrVal.join(', '));
	}
});


