import $ from 'jquery';
import Closer from './Closer';

var arrOpener = [];
var arrCssTE = [];
var isOpen = false;

export default class Toggler {

	constructor(objCssTE, addCloser) {
		this.setup(objCssTE, addCloser);

		Closer.onClose(this.close.bind(Closer))
	}

	setup(objCssTE, addCloser) {
		if(typeof addCloser !== 'undefined') {

		}
		arrCssTE = [];

		for (var sel in objCssTE) {
			if(objCssTE.hasOwnProperty(sel) ) {
				var $e = $(sel);

				if($e.length < 1) {continue}

				arrCssTE.push({
					cssClassTog: objCssTE[sel],
					$element: $e,
					cssSelector: sel
				});
			}
		}

		return this;
	}
	open() {
		isOpen = true;
		arrCssTE.forEach(function(item) {
			item.$element.addClass(item.cssClassTog);
		});

		Closer.open();

		return this;
	}
	close() {
		isOpen = false;
		arrCssTE.forEach(function(item) {
			item.$element.removeClass(item.cssClassTog);
		});
		Closer.close();

		return this;
	}
	toggle() {
		if(isOpen) {
			this.close();
		} else {
			this.open();
		}

		return this;
	}
	isOpen() {
		return isOpen;
	}
	isClose() {
		return !isOpen;
	}
}
