import $ from 'jquery';

var defaultOptions = {
	id: 'closer',
	tag: 'div',
	cssOpener: 'open'
};

var closeCallback = [];

var Closer = {
	_createElement() {
		var ele = document.createElement(defaultOptions.tag);
		ele.id = defaultOptions.id;

		return $(ele).on('click', function() {
			if(this.dataset.closeAble) {
				closeCallback.forEach((callback)  => {
					callback();
				})
			}
		});
	},
	setup(options) {
		defaultOptions= $.extend(defaultOptions, options);
	},

	// @todo closeAble option
	open(closeAble) {
		if(typeof closeAble === 'undefined') {
			closeAble = true;
		}
		this.closeElement = this._createElement();
		this.closeElement.get(0).dataset.closeAble = closeAble;
		if(closeAble) {
			this.closeElement.addClass('closeAble')
		}
		$(document.body).append(this.closeElement);
		setTimeout(() => {
			this.closeElement.addClass(defaultOptions.cssOpener);
		}, 2);
		return this;
	},
	close() {
		this.closeElement.detach();
		this.closeElement = null;
		return this;
	},
	onClose(callback) {
		closeCallback.push(callback);
		return this;
	}
};

export default Closer;